import React, { useRef, useCallback, useEffect, useMemo } from 'react';
import throttle from 'lodash/throttle';
import useHasMouse from 'src/hooks/useHasMouse';
import useIsPotato from 'src/hooks/useIsPotato';

interface UseGradientTextProps {
  gradientColors: string;
}

const useGradientText = <T extends HTMLElement, P extends HTMLElement>({
  gradientColors,
}: UseGradientTextProps) => {
  const elementRef = useRef<T>(null);
  const parentRef = useRef<P>(null);
  const gradientPosition = useRef({ x: 50, y: 50 });
  const animationFrameRef = useRef<number>();
  const gradientColorsRef = useRef(gradientColors);
  const hasMouse = useHasMouse();
  const isPotato = useIsPotato();

  useEffect(() => {
    gradientColorsRef.current = gradientColors;
  }, [gradientColors]);

  const animateGradient = useCallback((targetX: number, targetY: number) => {
    if (!elementRef.current) return;

    const currentX = gradientPosition.current.x;
    const currentY = gradientPosition.current.y;

    const nextX = currentX + (targetX - currentX) * 0.1;
    const nextY = currentY + (targetY - currentY) * 0.1;

    gradientPosition.current = { x: nextX, y: nextY };

    elementRef.current.style.background = `radial-gradient(circle at ${nextX}% ${nextY}%, ${gradientColorsRef.current})`;
    elementRef.current.style.backgroundClip = 'text';
    elementRef.current.style.color = 'transparent';

    if (Math.abs(nextX - targetX) > 1 || Math.abs(nextY - targetY) > 1) {
      animationFrameRef.current = requestAnimationFrame(() => {
        animateGradient(targetX, targetY);
      });
    }
  }, []);

  const throttled = useMemo(() => {
    return throttle((e: React.MouseEvent) => {
      if (!parentRef.current || !elementRef.current) return;

      const { left, top, width, height } =
        parentRef.current.getBoundingClientRect();
      const x = ((e.clientX - left) / width) * 100;
      const y = ((e.clientY - top) / height) * 100;

      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = requestAnimationFrame(() => {
        animateGradient(x, y);
      });
    }, 16);
  }, [animateGradient]);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!hasMouse || isPotato) return null;
      return throttled(e);
    },
    [hasMouse, isPotato, throttled],
  );

  useEffect(() => {
    return () => {
      throttled.cancel();
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [throttled]);

  return useMemo(() => {
    return { elementRef, parentRef, handleMouseMove };
  }, [handleMouseMove]);
};

export default useGradientText;
